<template>
    <v-app>
        <v-dialog max-width="900" v-model="dialog">
            <v-card>
              <v-toolbar dark>
                <v-card-title class="headline">
                  {{`${edit?'Update':"Add"} content`}}
                  <hr>
                </v-card-title>

                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-spacer></v-spacer>
                  <v-btn icon  @click="cancelForm">
                    <i class="fas fa-times"></i>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>

                <v-card-text>
                  <v-container>
                    <div class="row">
                        <div class="col-12">
                            <v-text-field dense label="Title" outlined
                                          v-model="content.title"></v-text-field>
                            <span class="text-danger" v-if="$v.content.title.$error">Title is required</span>
                        </div>
                        <div class="col-6">
                            <v-text-field dense label="Sub title" outlined
                                          v-model="content.sub_title"></v-text-field>
                        </div>
                        <div class="col-6">
                            <v-text-field dense label="icon" outlined
                                          v-model="content.icon"></v-text-field>
                        </div>

                        <div class="col-12">
                            <label for="">Description</label>
                            <ckeditor :config="editorConfig" v-model="content.description"></ckeditor>
                            <!-- <span class="text-danger" v-if="$v.page.content.$error">Content is required</span> -->
                        </div>
                      <v-col md="6">
                        <v-select
                            :items="[1,2,3,4,5,6]"
                            dense
                            label="No Of Column"
                            outlined
                            v-model="content.no_of_columns"
                        >
                        </v-select>
                      </v-col>
                      <v-col md="6">
                        <v-file-input
                            :rules="rules"
                            outlined
                            dense
                            accept="image/*"
                            placeholder="Pick an Image"
                            prepend-icon="mdi-camera"
                            label="Image"
                            v-model="content.image"
                        ></v-file-input>
                      </v-col>
                        <v-col md="2">
                          Is link ?
                            <v-switch
                                :label="content.is_link ? 'Yes' : 'No'"
                                    v-model="content.is_link"
                            >
                            </v-switch>
                        </v-col>
                        <v-col md="5" v-if="content.is_link">
                            <v-text-field dense label="Link" outlined
                                          v-model="content.link"></v-text-field>
                        </v-col>
                        <v-col md="5" v-if="content.is_link">
                            <v-select
                                    :items="[
                                        {text:'Self',value:'self'},
                                        {text:'New tab',value:'new tab'},
                                        ]" item-text="text" item-value="value"
                                    dense
                                    label="Link open"
                                    outlined
                                    v-model="content.link_open_type"
                            >
                            </v-select>

                        </v-col>

                        <v-col cols="12" v-if="content.image_path">
                            <v-img width="500" :src="content.image_path['real']">

                            </v-img>
                        </v-col>
                        <div class="col-12 mt-5">
                          Status
                            <v-switch
                                :label="content.is_active ? 'Active' : 'Inactive'"
                                    v-model="content.is_active"
                            >
                            </v-switch>
                        </div>

                    </div>
                  </v-container>
                </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    @click="cancelForm"
                    class="btn btn-standard cancel-btn"
                    depressed


                >Cancel
                </v-btn>
                <v-btn
                    :disabled="sizeExceed"
                    :loading="isBusy"
                    @click="createOrUpdate"

                    class="text-white ml-2 btn btn-primary"
                    depressed
                >Save
                </v-btn>
              </v-card-actions>
            </v-card>

        </v-dialog>
    </v-app>
</template>
<script>
    import {required} from "vuelidate/lib/validators";
    import HomePageSectionContentService from "@/core/services/homepage-section/HomePageSectionContent/HomePageSectionContentService";
    const homePageSectionContentService = new HomePageSectionContentService();
    export default {
        props:['section_id'],
        validations: {
            content: {
                title: {required}
            }
        },
        data() {
            return {
                dialog: false,
                edit: false,
                sizeExceed: false,
                isBusy: false,
                rules: [
                    value => !value || value.size < 2000000 || 'Image size should be less than 2 MB!',
                ],
                types: [
                    {
                        "name": 'Slider',
                        "value": 'slider'
                    },
                    {
                        "name": 'Info Box',
                        "value": 'info_box'
                    },

                    {
                        "name": 'News ',
                        "value": 'news'
                    },
                    {
                        "name": 'Banner',
                        "value": 'banner'
                    },
                    {
                        "name": 'Programs',
                        "value": 'programs'
                    },

                    {
                        "name": 'Introduction ',
                        "value": 'introduction'
                    },
                    {
                        "name": 'News ',
                        "value": 'news'
                    },
                    {
                        "name": 'Event',
                        "value": 'event'
                    },
                    {
                        "name": 'Exam And Result',
                        "value": 'result'
                    },

                    {
                        "name": 'Why Study Here ',
                        "value": 'study_here'
                    },
                    {
                        "name": 'Virtual Tour ',
                        "value": 'virtual_tour'
                    },
                    {
                        "name": 'Gallery',
                        "value": 'gallery'
                    },
                    {
                        "name": 'Videos',
                        "value": 'video_gallery'
                    },

                    {
                        "name": 'Blogs',
                        "value": 'blogs'
                    },
                ],
                editorConfig: {
                    versionCheck: false,
                    scayt_autoStartup: true,
                    allowedContent: true,
                    pasteFromWordRemoveFontStyles: true,
                    pasteFromWordRemoveStyles: true,
                    pasteFromWordNumberedHeadingToList: true,
                    toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList', 'SpellChecker', 'Scayt', 'PasteFromWord']]

                },
                content: {
                    title: null,
                    sub_title:null,
                    is_active: true,
                    no_of_columns:null,
                    is_link:false,
                    link_open_type:null,
                    link:null,
                    image_path:null,
                }
            };
        },
        methods: {
            cancelForm(){
                this.resetForm()
                this.hideModal()
            },
            showModal(id = null) {
                if (id) {
                    this.edit = true
                    this.getHomePageSection(id)
                }else{
                    this.resetForm()
                }
                this.dialog = true;
            },
            hideModal() {
                this.dialog = false;
            },
            toggleModal() {
                this.$refs["my-modal"].toggle("#toggle-btn");
            },
            getHomePageSection(id) {
                homePageSectionContentService.show(this.section_id,id).then(response => {
                    this.content = response.data.home_page_section_content
                })
            },
            generateFd() {
                // console.log(this.content)
                let fd = new FormData();
                for (let key in this.content) {
                    if (
                        key == "image" &&
                        this.content["image"] &&
                        this.content["image"] != null
                    ) {
                        fd.append("image", this.content[key]);
                    } else if (key == "is_active" ) {
                        fd.append("is_active", this.content['is_active'] ? 1 : 0);
                        delete this.content.is_active
                    }
                    else if (key == "is_link") {
                        fd.append("is_link", this.content['is_link'] ? 1 : 0);
                      delete this.content.is_link
                    }else {
                        if (this.content[key] != null) {
                            fd.append(key, this.content[key]);
                        }
                    }
                }
                return fd;
            },
            createOrUpdate() {
                this.$v.content.$touch();
                if (this.$v.content.$error) {
                    setTimeout(() => {
                        this.$v.content.$reset();
                    }, 3000);
                } else {
                    let fd = this.generateFd();
                    if (this.edit) {
                        this.updateSection(fd);
                    } else {
                        this.createSection(fd);
                    }
                }
            },
            updateSection(fd) {
                this.isBusy = true;
                homePageSectionContentService
                    .update(this.section_id,this.content.id, fd)
                    .then(response => {
                        this.isBusy = false;
                        this.$snotify.success("Information updated");
                        this.hideModal();
                        this.$emit("refresh_content");
                        this.resetForm();
                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                        // console.log(error);
                    });
            },

            checkSize(file) {
                let size = file.size / 1024 / 1024;
                let mbSize = size.toFixed(2);
                if (mbSize > 20) {
                    this.sizeExceed = true;
                } else {
                    this.sizeExceed = false;
                }
            },
            createSection(fd) {
                this.isBusy = true;
                homePageSectionContentService
                    .store(this.section_id,fd)
                    .then(response => {
                        this.isBusy = false;
                        this.resetForm();
                        this.$snotify.success("Information added");
                        this.hideModal();
                        this.$emit("refresh_content");
                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                        // console.log(error);
                    });
            },
            resetForm() {
                this.$v.content.$reset();
                this.edit = false;
                this.content = {
                    title: null,
                    is_active: null
                };
            }
        }
    };
</script>
