<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Manage Content</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\  <router-link :to="{name:'home-page'}">
                  Homepage Section 
                </router-link>
                \ Content
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">

                  <v-btn
                      @click="addPhoto()"
                      class="mt-1 btn btn-primary"
                      style="color:#fff;"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add content
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="">
              <v-skeleton-loader
                  v-if="loading"
                  type="table-thead"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
            <table class="table">
              <thead>
                <tr class="px-3">
                <th>Title</th>
                <th>Section</th>
                <th>Action</th>
              </tr>
              </thead>
              <draggable v-show="contents.length>0" :move="checkMove" class="text-left" handle=".handle" tag="tbody"
                        v-model="contents">
                <tr :key="index" v-for="(home_page_section, index) of contents">
                  <td scope="row">
                    <i class="cursor-drag fas fa-sort handle pr-2"></i>
                    {{ home_page_section.title }}
                    <i :class="home_page_section.is_active?'dot-active':'dot-inactive'"
                      class="fas fa-circle"></i>
                  </td>
                  <td>
                    {{home_page_section.section}}
                  </td>

                  <td>
                    <b-dropdown
                            no-caret
                            no-flip
                            right
                            size="sm"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            variant="link"
                    >
                      <template v-slot:button-content>
                        <slot>
                                                          <span>
                                                                      <i class="flaticon-more-1"></i>
                                                                    </span></slot>
                      </template>
                      <!--begin::Navigation-->
                      <div class="navi navi-hover ">

                        <b-dropdown-text class="navi-item" tag="div"
                                        v-if="checkIsAccessible('album' ,'edit')">
                          <a @click="editSection(home_page_section.id)" class="navi-link"
                            href="#">
                                                              <span class="navi-icon">
                                                                <i class="flaticon-edit"></i>
                                                              </span>
                            <span class="navi-text"> Edit </span>
                          </a>
                        </b-dropdown-text>

                        <b-dropdown-text class="navi-item" tag="div"
                                        v-if="checkIsAccessible('album' ,'delete')">
                          <a @click="deleteSection(home_page_section.id)"
                            class="navi-link"
                            href="#">
                                                              <span class="navi-icon">
                                                                <i class="fas fa-trash"></i>
                                                              </span>
                            <span class="navi-text">  Delete</span>
                          </a>
                        </b-dropdown-text>

                      </div>
                      <!--end::Navigation-->
                    </b-dropdown>

                  </td>
                </tr>
              </draggable>
              <tr v-if="contents.length == 0" >
                <td colspan="3" class="text-center">
                  Data not available
                </td>
              </tr>
            </table>
            </div>

        </div>
      </div>
    </div>
    </div>
    <create-and-update :section_id="section_id" ref="add-photo" @refresh_content="getValues"></create-and-update>
    <overlay :status="overlay_status"></overlay>
  </v-app>
</template>
<script>
import HomePageSectionContentService from "@/core/services/homepage-section/HomePageSectionContent/HomePageSectionContentService";
const homePageSectionContentService = new HomePageSectionContentService();

import CreateAndUpdate from "./CreateAndUpdate";
import Overlay from "@/view/pages/overlay/Index"
import draggable from 'vuedraggable'

export default {
  components: {
    draggable,
    CreateAndUpdate, Overlay
  },
  data() {
    return {
      contents: [],
      overlay_status:false,
      loading:false
    };
  },

  computed: {
    section_id() {
      return this.$route.params.id;
    }
  },
  mounted() {
    this.getValues();
  },
  updated() {
    // this.getValues();
  },
  watch: {
    section_id(newValue, oldValue) {
      this.getValues();
    }
  },
  methods: {
    getValues() {
      this.loading=true;
      homePageSectionContentService
          .paginate(this.section_id)
          .then(response => {
            this.contents = response.data;
            this.loading=false;
          });
    },
    addPhoto() {
      this.$refs["add-photo"].showModal();
    },
    editSection(id) {
      this.$refs["add-photo"].showModal(id);
    },
    deleteSection(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            homePageSectionContentService
                    .delete(this.section_id,id)
                    .then(response => {
                      this.$snotify.success("Information deleted");
                      this.getValues();
                    })
                    .catch(error => {
                      //console.log(error);
                    });
          }
        }
      });
    },
    sort() {
      homePageSectionContentService.sort(this.section_id,this.contents).then((res)=>{
        this.$snotify.success("Sorted");
        this.getValues()
      }).catch(err=>{
        this.$snotify.success("Sorted Error");
      })
    },
    checkMove: function (evt) {
      console.log(evt)
      let futureIndex = (parseInt(evt.draggedContext.futureIndex) + 1);
      let item = evt.draggedContext.element;
      let itemId = item.id;
      this.sort(itemId, futureIndex)
    },
    deletePhoto(id) {
      this.overlay_status = true
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            this.overlay_status = false
            homePageSectionContentService.delete(this.section_id, id).then(response => {
              this.$snotify.success("Information deleted");
              this.getValues();
            });
          }
        }
      });
    },
    toogleFeaturedPhoto(value) {
      let data = value;
      data.is_featured = !data.is_featured;
      delete data.image_path;
      homePageSectionContentService.update(this.section_id, data.id, data).then(response => {
        this.getValues();
      });
    }
  }
};
</script>
<style scoped>
.colleges-gallery-card {
  position: relative;
}

.colleges-gallery-card .kist-btn-delete,
.colleges-gallery-card .college-btn-feature,
.colleges-gallery-card .college-btn-feature-alt {
  position: absolute;
  right: 8px;
  display: inline-block;
  padding: 3px 0;
  border-radius: 5px;
  border: none;
  min-width: 28px;
}

.colleges-gallery-card .kist-btn-delete i,
.colleges-gallery-card .college-btn-feature i,
.colleges-gallery-card .college-btn-feature-alt * {
  font-size: 12px;
}

.colleges-gallery-card .kist-btn-delete {
  top: 10px;
  background-color: #cc0000;
}

.colleges-gallery-card .kist-btn-delete i {
  color: #fff;
}

.colleges-gallery-card .college-btn-feature,
.colleges-gallery-card .college-btn-feature-alt {
  top: 40px;
  background-color: #004c98;
}

.colleges-gallery-card .college-btn-feature-alt {
  padding: 3px 5px;
  top: 10px;
  left: 8px;
  right: auto;
}

.colleges-gallery-card .college-btn-feature i,
.colleges-gallery-card .college-btn-feature-alt * {
  color: #fff;
}
</style>
