import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class HomePageSectionContentService {

      #api = null;

      constructor() {
            this.#api = `${API_URL}user/homepage-sections/`;

      }

      paginate(id, type = null) {
            let url = `${this.#api}${id}/content`;
            if (type)
                  url = url + '?type=' + type;
            return apiService.get(url);
      }

      update(id,contentId,  data) {
            let url = `${API_URL}user/home-content/${id}/content/${contentId}/update`
            return apiService.post(url,data);


      }

      store(id, data) {
            let url = `${this.#api}${id}/content`;
            return apiService.post(url, data);

      }
      sort(id, data) {
            let url = `${this.#api}${id}/content/sort`;
            return apiService.post(url, data);
      }

      show(contentId,id) {
            let url = `${this.#api}${contentId}/content/${id}`
            return apiService.get(url);
      }

      delete(homepageSectionId, id) {
            let url = `${this.#api}${homepageSectionId}/content/${id}`
            return apiService.delete(url);
      }
}
